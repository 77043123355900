<template>
    <div class="fullPageForm">

        <div class="loginFormHolder">
            <div class="loaderHolder" v-if="isLoading(['handleLogin'])"><PageLoader/></div>
            <LoginPin v-if="showPinForm" @back="() => { showPinForm = false; }" @confirm="handleSubmit" v-model="form.pin"/>
            <form v-else @submit.prevent="handleSubmit" class="form">
                <div class="loginTitle">Log in</div>
                <Input v-model="form.username" :error="errors.username" name="Email address or Username" type="text" placeholder="Enter email address or username"/>
                <Input v-model="form.password" :error="errors.password" name="Password" type="password" placeholder="Password"/>
                <button type="submit" class="default">Log in</button>
                <div class="forgotPasswordLink"><router-link to="/restore">Forgot your password?</router-link></div>
            </form>
        </div>


        <div class="imageContenHolder">
            <div class="contentGrid">
                <img src="/images/logo-light.png" width="200">
                <div class="largeText">Welcome back</div>
                <div class="smallText">Log in now to get access to all your loads, income and statistics.</div>
            </div>
        </div>

    </div>
</template>

<script>
import LoginPin from '../components/LoginPin.vue'

    export default {
        title: "Log in",
        components: {
            LoginPin
        },
        data() {
            return {
                form: {
                    username: '',
                    password: '',
                    pin: ''
                },
                errors: {},
                showPinForm: false
            }
        },
        computed: {
            isValidForm() {
                return (this.form.password.length >= 8 && this.form.username.length >= 5);
            }
        },
        methods: {
            handleSubmit() {
                this.errors = {};

                if(this.form.pin.length != 6) {
                    this.showPinForm = true;
                    return;
                } 

                this.ajax('handleLogin', {
                    method: 'POST',
                    url: '/login',
                    data: this.form
                }, (err, body) => {

                    if(err) {
                        if(body.errors) {
                            this.errors = body.errors;
                            this.showPinForm = false;
                            this.form.pin = '';
                        } else if(body.message) {
                            alert(body.message);

                            this.form.pin = '';
                            if(body.invalidPin) {
                                this.showPinForm = true;
                            } else {
                                this.showPinForm = false;
                            }

                        } else {
                            alert("Something went wrong. Please, try again!");
                        }
                        return;
                    }

                    if(body.token) {
                        // store token in app store
                        this.$store.commit('token', body.token);
                        localStorage.setItem("token", body.token);
                        this.$router.push({ path: '/app/dispatch' });
                    } else {
                        alert("Something went wrong. Please, try again!");
                    }

                });

            }
        }
    }
</script>

<style lang="scss" scoped>
.form {
    display: grid;
    row-gap: 20px;
    width: 100%;
    max-width: 350px;
}

.fullPageForm {
    min-height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.loginFormHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    position: relative;
    z-index: 2;
    .loaderHolder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255,255,255,0.5);
        z-index: 3;
    }
}

.imageContenHolder {
    background-position: center;
    background-size: cover;
    background-image: url('/images/login-bg.jpg');
    border-left: 1px solid $borderColor;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 30px;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }
    .contentGrid {
        position: static;
        z-index: 2;
        display: grid;
        justify-content: center;
        justify-items: center;
        row-gap: 20px;
        text-align: center;
        .largeText {
            font-weight: 700;
            font-size: 36px;
            line-height: 54px;
            /* identical to box height */
            text-transform: uppercase;
            color: #FFFFFF;
            font-family: 'Poppins';
        }
        .smallText {
            max-width: 492px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: capitalize;

            color: #FFFFFF;
        }
    }
}

.loginTitle {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    position: relative;
    margin-bottom: 25px;
    z-index: 1;
    &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 48px;
        height: 4px;
        background: $text;
        transform: translateY(10px);
    }
}

.forgotPasswordLink {
    text-align: center;
}
</style>