<template>
    <div class="loginPin">
        <h2>Enter your pin</h2>
        
        <div class="enteredPin">
            <button class="entered" :key="index" v-for="index in numOfEnteredKeys"></button>
            <button class="empty" :key="index" v-for="index in numOfEmptyKeys"></button>
        </div>

        <div class="keys">
            <div class="key" :key="item" v-for="item of keys">
                <a href="" @click.prevent="action(item)" v-if="item == 'back' || item == 'clear'">{{ item }}</a>
                <button v-else @click="enterKey(item)">{{ item }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['modelValue'],
        data() {
            return {
                pin: this.modelValue || '',
                keys: [1,2,3,4,5,6,7,8,9, 'back', 0, 'clear']
            }
        },
        mounted() {
            window.addEventListener('keypress', this.eventListener);
        },
        beforeMount() {
            window.removeEventListener('keypress', this.eventListener);
        },
        methods: {
            eventListener(e) {
                let key = e.key;

                for(const item of this.keys) {
                    if(item == key) this.enterKey(key);
                }

            },
            enterKey(key) {
                if(this.numOfEnteredKeys >= 6) return;
                this.pin += key;
            },
            action(key) {
                if(key == 'back') {
                    this.pin = '';
                    this.$emit('back');
                    return;
                }

                if(key == 'clear') {
                    this.pin = '';
                    return;
                }
            }
        },
        computed: {
            numOfEnteredKeys() {
                return this.pin.length;
            },
            numOfEmptyKeys() {
                return 6 - this.numOfEnteredKeys;
            }
        },
        watch: {
            pin() {
                this.$emit('update:modelValue', this.pin);
                if(this.numOfEnteredKeys >= 6) {
                    this.$emit('confirm');
                }
            },
            modelValue() {
                this.pin = this.modelValue;
            }
        }
    }
</script>

<style lang="scss" scoped>

.loginPin {
    display: grid;
    row-gap: 30px;
    justify-content: center;
    justify-items: center;
}

h2 {
    text-align: center;
}

.keys {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 240px;
    .key {
        width: 80px;
        height: 80px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 1px solid $buttonColor;
            background: $buttonColor2;
            font-weight: bold;
            font-size: 20px;
            color: $buttonColor;
            transition: ease 0.5s;
            &:hover {
                color: #fff;
                background: $buttonColor;
            }
        }
    }
}

.enteredPin {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        width: 10px;
        height: 10px;
        border: 1px solid $buttonColor;
        border-radius: 50%;
        background: transparent;
        margin: 0 7px;
        &.entered {
            background: $buttonColor;
        }
    }
}
</style>